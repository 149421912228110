import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="30"
      height="30"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        strokeLinejoin: 'round',
        strokeMiterlimit: 2,
      }}
      viewBox="0 0 176 176"
      {...props}
    >
      <path
        d="M0 87.898C0 39.353 39.354 0 87.898 0c48.545 0 87.899 39.353 87.899 87.898 0 48.545-39.354 87.898-87.899 87.898C39.354 175.796 0 136.443 0 87.898"
        style={{
          fill: '#fff',
          fillRule: 'nonzero',
        }}
      />
      <path
        d="M75.473 57.968c-1.64 0-3.148.944-3.86 2.44l-12.467 26-5.493-5.356a4.28 4.28 0 0 0-5.676-.266l-5.723 4.617-25.955.018a4.277 4.277 0 0 0 .002 8.553l27.479.008a4.274 4.274 0 0 0 2.694-.953l3.919-3.175 7.061 6.862a4.323 4.323 0 0 0 3.74 1.153 4.277 4.277 0 0 0 3.112-2.378l10.912-22.764 17.245 41.139a4.304 4.304 0 0 0 3.802 2.636h.156a4.3 4.3 0 0 0 3.825-2.355l10.32-20.351h48.913a4.278 4.278 0 0 0 4.291-4.288 4.29 4.29 0 0 0-4.291-4.292h-51.535c-1.62 0-3.102.911-3.835 2.355L96.79 102 79.431 60.607a4.263 4.263 0 0 0-3.847-2.639h-.111Z"
        style={{
          fill: '#5e8ce6',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}
